<template>
  <!-- eslint-disable -->
  <div class="container">
    <main class="content">
      <!-- Post Generation Form -->
      <section class="post-generation">
        <div class="prompts_container">
          <div class="text-group-first">
            <span class="type-span">Choose A Post Type</span>
            <select v-model="selectedCategory" class="select">
              <option selected value="Famous Quotes">Famous Quotes</option>
              <option value="Educational Content">Educational Content</option>
              <option value="Seasonal-Holiday Posts">Seasonal/Holiday Posts</option>
              <option value="Engagement Posts">Engagement Posts</option>
              <option value="Fun Facts">Fun Facts</option>
              <option value="Humorous Posts">Humorous Posts</option>
              <option value="Quote of the Day">Quote of the Day</option>
              <option value="Interactive Content">Interactive Content</option>
            </select>
          </div>
          <div class="text-group">
            <span class="type-span">Additional Instructions (not required)</span>
            <textarea v-model="additionalInfo" type="text" class="input-text"
                      placeholder="Provide additional information to further refine the post."></textarea>
          </div>
        </div>
        <b-button variant="success" @click="generatePost" :disabled="loading">
          <span>Generate Post</span>
        </b-button>
      </section>

      <b-modal v-model="showSocialPostModal"
        :title="`Are you sure you want to post to ${postingType.charAt(0).toUpperCase() + postingType.slice(1)}`">
        <div v-if="postingItem">
          <div><strong>Review Text: </strong>
            <!-- eslint-disable-next-line -->
            <span v-html="postingItem.text"></span>
          </div>
          <div v-if="postingType === 'instagram' && postingItem.instagram_post" class="mt-3">
            Posted to Instagram on {{ postingItem.instagram_post.front_date }}<br />
            Link: <a :href="'https://www.instagram.com/p/' + postingItem.instagram_post.ig_shortcode"
              target="_blank">https://www.instagram.com/p/{{ postingItem.instagram_post.ig_shortcode }}</a>
          </div>
          <div v-if="postingType === 'facebook' && postingItem.facebook_post" class="mt-3">
            Posted to Facebook on {{ postingItem.facebook_post.front_date }}<br />
            Link: <a :href="'https://www.facebook.com/' + postingItem.facebook_post.post_id"
              target="_blank">https://www.facebook.com/{{ postingItem.facebook_post.post_id }}</a>
          </div>
        </div>
        <template v-slot:modal-footer>
          <b-button variant="danger" @click="showSocialPostModal = false">Cancel</b-button>
          <b-button variant="success" @click="postSocial(postingItem, postingType)"><b-spinner
              v-if="postingItem.working" small /> Post</b-button>
        </template>
      </b-modal>

      <div class="modal_container review_post_modal_wrapper fade" id="review_post_modal">
        <div class="review_post_modal">
          <span id="modal_close" class="modal_close_icon" @click="fadeOut">&times;</span>
          <div class="modal_content_wrapper">
            <div class="modal_content">
              <div class="post_content">
                <div class="post">
                  <div v-if="loading" class="loading_div">
                    <h2 class="generate">Generating Post!</h2>
                    <b-spinner class="spin" large></b-spinner>
                  </div>
                  <div v-else>
                    <div class="post_text_wrapper">
                      <p id="post_text" class="post_text editable_text" dir="ltr" contenteditable @input="updatePostText" v-html="newPost.text"></p>
                      <br>
                      <p id="post_tags" class="post_tags editable_text" dir="ltr" contenteditable @input="updatePostHashtags" v-html="newPost.hashtags"></p>
                    </div>
                    <div class="post_image_wrapper">
                      <img id="post_image" :src="newPost.imageUrl" alt="" class="post_image" />
                    </div>
                  </div>
                </div>
                <button v-if="!loading" class="edit_post_button" id="edit_post" @click="editPost">Edit</button>
              </div>
              <div v-if="!loading" class="regenrate_buttons_container">
                <b-button variant="success" class="regenerate_btn"  @click="regenerate('image')" :disabled="loadingImage">
                  <b-spinner v-if="loadingImage" small></b-spinner>
                  <span>Regenerate Image</span>
                </b-button>
                <b-button variant="success" class="regenerate_btn"  @click="regenerate('post')" :disabled="loadingPost">
                  <b-spinner v-if="loadingPost" small></b-spinner>
                  <span>Regenerate Post</span>
                </b-button>
              </div>
              <div v-if="!loading" class="social_media_buttons_container">
                <b-button variant="success" class="social_post_btn" @click="postSocial(null, 'facebook')" :disabled="loadingFacebook">
                  <b-spinner v-if="loadingFacebook" small></b-spinner>
                  <span>Post To Facebook</span>
                </b-button>
                <b-button variant="success" class="social_post_btn" @click="postSocial(null, 'instagram')" :disabled="loadingInstagram">
                  <b-spinner v-if="loadingInstagram" small></b-spinner>
                  <span>Post To Instagram</span>
                </b-button>
                <b-button variant="success" class="social_post_btn" @click="postSocial(null, 'instagram', true)" :disabled="loadingFbIg">
                  <b-spinner v-if="loadingFbIg" small></b-spinner>
                  <span>Post To FB & IG</span>
                </b-button>
              </div>
            </div>
          </div>
          <button v-if="!loading" class="save_btn" id="save_btn" @click="saveNewPost">Save</button>
        </div>
      </div>

      <!-- Recent Posts -->
      <section v-if="posts.length" class="recent-posts">
        <h2 class="section-title">Recent Posts</h2>
        <div v-for="post in posts" :key="post.date" class="recentPost">
          <div class="post-header">
            <span class="post-title" v-html="post.text"></span>
            <span class="post-title" v-html="post.hashtags"></span>
            <div class="post-buttons">
              <div class="buttonspan">
                <b-button v-if="user.business.facebook_token && user.business.facebook_token.fb_page_id" size="sm"
                  :class="{ 'bg-success': post.facebook_post, 'border-success': post.facebook_post }"
                  class="post-btn mt-2 mr-2 bg-primary border-primary" @click="confirmPostSocial(post, 'facebook')">
                  <span v-if="post.facebook_post">Posted</span><span v-else>Post</span> to Facebook
                </b-button>
                <span v-if="post.facebook_post" class="formatteddate">{{ formattedDate(post) }}</span>
              </div>
              <div class="buttonspan">
                <b-button v-if="user.business.facebook_token && user.business.facebook_token.ig_identifier" size="sm"
                  :class="{ 'bg-success': post.instagram_post, 'border-success': post.instagram_post }"
                  class="post-btn mt-2 bg-primary border-primary" @click="confirmPostSocial(post, 'instagram')">
                  <span v-if="post.instagram_post">Posted</span><span v-else>Post</span> to Instagram
                </b-button>
                <span v-if="post.instagram_post" class="formatteddate">{{ formattedInstagramDate(post) }}</span>
              </div>
            </div>
          </div>
          <img :src="post.imageUrl" alt="Generated Image" class="post-image">
        </div>
        <div class="load-div">
          <button v-if="hasMorePosts"
            class="btn post-btn mt-2 mr-2 bg-primary border-primary btn-secondary btn-lr load-more-btn"
            @click="loadMore">Load
            More</button>
        </div>
      </section>
      <b-modal  content-class="keymodal" v-model="showConnectAlert">
        <p><strong>Important:</strong> Connect Your Social Media Accounts</p>
        <p>To start automating your social media content, please connect your social media accounts.</p>
        <p>Connecting your accounts ensures seamless content creation and scheduling, making your social media management effortless.</p>
        <b-button variant="primary" @click="gotoSettings">Connect Your Accounts</b-button>
      </b-modal>
    </main>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'SocialPosts',
  data() {
    return {
      selectedCategory: 'Famous Quotes',
      additionalInfo: '',
      posts: [],
      postingItem: null,
      postingType: '',
      showSocialPostModal: false,
      loading: false,
      showModal: false,
      newPost: {
        text: '',
        imageUrl: '',
        hashtags: '',
        id: ''
      },
      loadedPostsCount: 2,
      hasMorePosts: true,
      loadingText: false,
      loadingImage: false,
      loadingPost: false,
      loadingFacebook: false,
      loadingInstagram: false,
      loadingFbIg: false,
      showConnectAlert: false
    };
  },

  computed: {
    formattedDate() {
      return (post) => {
        if (post && post.facebook_post && post.facebook_post.front_date) {
          const date = new Date(post.facebook_post.front_date);
          const options = {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
          };
          return date.toLocaleString('en-US', options);
        } else {
          return ''; // Handle cases where the date is not available
        }
      };
    },
    formattedInstagramDate() {
      return (post) => {
        if (post && post.instagram_post && post.instagram_post.front_date) {
          const date = new Date(post.instagram_post.front_date);
          const options = {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
          };
          return date.toLocaleString('en-US', options);
        } else {
          return ''; // Handle cases where the date is not available
        }
      };
    },
  },

  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user']);
    this.fetchPosts();
    this.loading = false;

    const token = this.$route.params.token;
    if (token) {
      const type = this.$route.params.type;
      const add = this.$route.params.add;

      this.selectedCategory = type;
      this.additionalInfo = add;
      this.generatePost();
    }

    if(this.user.google_accounts && this.user.google_accounts.length >= 1 && !this.user.business.facebook_token && this.user.business.is_social_fusion){
      setTimeout(() => {
        this.showConnectAlert = true;
      }, 250);
    }
  },

  methods: {
    gotoSettings() {
      window.location.href = "/business/settings";
    },
    editPost() {
      let elements = document.querySelectorAll('.editable_text');
      console.log(elements);
      elements.forEach(e => {
        if (e.getAttribute('contenteditable') === 'true') {
          e.setAttribute('contenteditable', 'false');
          e.style.border = 'none'
          e.style.padding = '0'
          document.getElementById('edit_post').innerHTML = "Edit";

        } else {
          e.setAttribute('contenteditable', 'true');
          e.style.border = '1px solid black'
          e.style.padding = '2px'
          document.getElementById('edit_post').innerHTML = "Done";
        }
      });

    },
    updatePostText(event) {
      const el = event.target;
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);

      // Save current selection
      const startOffset = range.startOffset;
      const endOffset = range.endOffset;

      this.newPost.text = event.target.innerText; // Update newPost.text on input change
      this.$nextTick(() => {
        this.restoreSelection(el, startOffset, endOffset);
      });
    },
    updatePostHashtags(event) {
      const el = event.target;
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);

      // Save current selection
      const startOffset = range.startOffset;
      const endOffset = range.endOffset;

      this.newPost.hashtags = event.target.innerText; // Update newPost.hashtags on input change
      // this.handleInput(event.target);

      // Restore selection
      this.$nextTick(() => {
        this.restoreSelection(el, startOffset, endOffset);
      });
    },
    restoreSelection(el, startOffset, endOffset) {
      const range = document.createRange();
      const sel = window.getSelection();
      range.setStart(el.firstChild, startOffset);
      range.setEnd(el.firstChild, endOffset);
      sel.removeAllRanges();
      sel.addRange(range);
    },
    handleInput(el) {
      this.$nextTick(() => {
        this.placeCaretAtEnd(el);
      });
    },
    placeCaretAtEnd(el) {
      el.focus();
      if (typeof window.getSelection !== "undefined" && typeof document.createRange !== "undefined") {
        let range = document.createRange();
        range.selectNodeContents(el);
        range.collapse(false);
        let sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      } else if (typeof document.body.createTextRange !== "undefined") {
        let textRange = document.body.createTextRange();
        textRange.moveToElementText(el);
        textRange.collapse(false);
        textRange.select();
      }else{
        console.error("neither if nor else")
      }
    },
    fadeOut() {
      let duration = 250;
      let element = document.getElementById('review_post_modal');

      element.style.transition = `opacity ${duration}ms`;
      element.style.opacity = 0;

      setTimeout(() => {
        element.style.display = 'none';
        element.classList.remove('show');
      }, duration);
    },
    loadMore() {
      this.loadedPostsCount += 2;
      this.fetchPosts();
    },
    confirmPostSocial(post, type) {
      this.postingItem = post;
      this.postingType = type;
      this.showSocialPostModal = true;
    },

    regenerate(type) {
      if (type === 'text') this.loadingText = true;
      if (type === 'image') this.loadingImage = true;
      if (type === 'post') this.loadingPost = true;

      let action = '';
      const prompt = this.generatePrompt();

      if (type === 'text') {
        action = 'posts/regenerateText';
      } else if (type === 'image') {
        action = 'posts/regenerateImage';
      } else if (type === 'post') {
        action = 'posts/regeneratePost';
      }

      this.$store.dispatch(action, {
        prompt: prompt,
        business_id: this.user.business.id,
        text: this.newPost.text
      })
        .then(response => {
          if (response) {
            if (type === 'text' || type === 'post') {
              this.newPost.text = response.text;
              this.newPost.hashtags = response.hashtags;
            }
            if (type === 'image' || type === 'post') {
              this.newPost.imageUrl = response.imageUrl;
            }
          }
        })
        .catch(error => {
          console.error('Error regenerating:', error);
          this.loading = false;
        })
        .finally(() => {
          if (type === 'text') this.loadingText = false;
          if (type === 'image') this.loadingImage = false;
          if (type === 'post') this.loadingPost = false;
        });
    },

    postSocial(post, type, both = false) {
      if (type === 'facebook') this.loadingFacebook = true;
      if (type === 'instagram' && !both) this.loadingInstagram = true;
      if (both) this.loadingFbIg = true;

      post = post || this.newPost;
      this.$set(post, 'working', true); // Use Vue's set method

      const postToSocialMedia = (type) => {
        return this.$store.dispatch('posts/postSocial', {
          type: type,
          post_id: post.id,
          user_id: this.user.id,
          business_id: this.user.business.id
        });
      };

      this.saveNewPost(false)
        .then(() => {
          post = post || this.newPost;
          if (both) {
            // First post to Instagram, then to Facebook
            postToSocialMedia('instagram')
              .then((res) => {
                this.$set(post, 'instagram_post', res.instagram_post);
                return postToSocialMedia('facebook');
              })
              .then((res) => {
                this.$set(post, 'facebook_post', res.facebook_post);
                this.$set(post, 'working', false);
                this.fadeOut();
                alert('Post has been successfully posted to Instagram and Facebook!');
                this.showSocialPostModal = false;
              })
              .catch((res) => {
                this.$set(post, 'working', false);
                console.log(res);
              })
              .finally(() => {
                this.loadingFbIg = false;
                this.showSocialPostModal = false;
              });
          } else {
            // Post to the specified type
            postToSocialMedia(type)
              .then((res) => {
                if (type === 'facebook') {
                  this.$set(post, 'facebook_post', res.facebook_post);
                } else if (type === 'instagram') {
                  this.$set(post, 'instagram_post', res.instagram_post);
                }
                this.$set(post, 'working', false);
                this.fadeOut();
                alert(`Post has been successfully posted to ${type.charAt(0).toUpperCase() + type.slice(1)}!`);
                this.showSocialPostModal = false;
              })
              .catch((res) => {
                this.$set(post, 'working', false);
                console.log(res);
              })
              .finally(() => {
                if (type === 'facebook') this.loadingFacebook = false;
                if (type === 'instagram' && !both) this.loadingInstagram = false;
                this.showSocialPostModal = false;
              });
          }
        })
        .catch((err) => {
          this.$set(post, 'working', false);
          console.log(err);
        });
    },

    generatePost() {
      const prompt = this.generatePrompt();
      this.loading = true;
      let element = document.getElementById('review_post_modal');

      element.style.opacity = 0;
      element.style.display = 'grid';
      element.style.alignItems = 'center';
      element.style.transition = `opacity 250ms`;

      requestAnimationFrame(() => {
        element.style.opacity = 1;
      });

      setTimeout(() => {
        element.classList.add('show');
      }, 250);

      this.$store
        .dispatch('posts/generatePost', {
          prompt: prompt,
          user_id: this.user.id,
          business_id: this.user.business.id,
        })
        .then((res) => {
          if (res) {
            const reply = res;

            this.newPost = {
              text: reply.text,
              hashtags: reply.hashtags,
              imageUrl: reply.imageUrl,
              date: new Date().toLocaleString(),
            };

            this.updateModalContent();
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    updateModalContent() {
      // This method updates the modal content with the generated post
      let postTags = document.getElementById('post_tags');
      let postImage = document.getElementById('post_image');
      let postText = document.getElementById('post_text');

      postTags.innerHTML = this.newPost.hashtags;
      postText.innerHTML = this.newPost.text;
      postImage.src = this.newPost.imageUrl;
    },

    saveNewPost(fade=true) {
      return new Promise((resolve, reject) => {
        if (this.newPost.text !== "" && this.newPost.text) {
          this.$store
            .dispatch('posts/savePost', {
              user_id: this.user.id,
              business_id: this.user.business.id,
              hashtags: this.newPost.hashtags,
              text: this.newPost.text,
              image_url: this.newPost.imageUrl,
            })
            .then((res) => {
              this.newPost.id = res.id;
              if (!this.posts.some(post => post.id === this.newPost.id)) {
                  this.posts.unshift(this.newPost);
              }
              resolve(res);
              if(fade){
                this.fadeOut();
                alert('Post has been successfully saved.');
              }
            })
            .catch((err) => {
              console.log(err);
              reject(err);
            });
        } else {
          resolve('done');
        }
      });
    },

    generatePrompt() {
      const date = new Date();
      const today = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
      const Holidays = {
        "2024-01-01": "New Year's Day",
        "2024-01-02": "Day After New Year's Day",
        "2024-01-03": "Festival of Sleep Day",
        "2024-01-04": "Trivia Day",
        "2024-01-05": "Bird Day",
        "2024-01-06": "Epiphany",
        "2024-01-07": "Orthodox Christmas Day",
        "2024-01-08": "Bubble Bath Day",
        "2024-01-09": "Static Electricity Day",
        "2024-01-10": "Houseplant Appreciation Day",
        "2024-01-11": "International Thank You Day",
        "2024-01-12": "Kiss a Ginger Day",
        "2024-01-13": "Make Your Dream Come True Day",
        "2024-01-14": "Orthodox New Year",
        "2024-01-15": "Martin Luther King Jr. Day",
        "2024-01-16": "Nothing Day",
        "2024-01-17": "Ditch New Year's Resolutions Day",
        "2024-01-18": "Winnie the Pooh Day",
        "2024-01-19": "Popcorn Day",
        "2024-01-20": "Penguin Awareness Day",
        "2024-01-21": "Squirrel Appreciation Day",
        "2024-01-22": "Answer Your Cat's Questions Day",
        "2024-01-23": "Pie Day",
        "2024-01-24": "Beer Can Appreciation Day",
        "2024-01-25": "Opposite Day",
        "2024-01-26": "Australia Day",
        "2024-01-27": "Chocolate Cake Day",
        "2024-01-28": "Data Privacy Day",
        "2024-01-29": "Puzzle Day",
        "2024-01-30": "Croissant Day",
        "2024-01-31": "Inspire Your Heart with Art Day",
        "2024-02-01": "National Freedom Day",
        "2024-02-02": "Groundhog Day",
        "2024-02-03": "Feed the Birds Day",
        "2024-02-04": "World Cancer Day",
        "2024-02-05": "National Weatherperson's Day",
        "2024-02-06": "International Day of Zero Tolerance to Female Genital Mutilation",
        "2024-02-07": "Send a Card to a Friend Day",
        "2024-02-08": "National Boy Scouts Day",
        "2024-02-09": "National Pizza Day",
        "2024-02-10": "Umbrella Day",
        "2024-02-11": "International Day of Women and Girls in Science",
        "2024-02-12": "Lincoln's Birthday",
        "2024-02-13": "World Radio Day",
        "2024-02-14": "Valentine's Day",
        "2024-02-15": "Susan B. Anthony Day",
        "2024-02-16": "Do a Grouch a Favor Day",
        "2024-02-17": "Random Acts of Kindness Day",
        "2024-02-18": "National Drink Wine Day",
        "2024-02-19": "Presidents' Day",
        "2024-02-20": "World Day of Social Justice",
        "2024-02-21": "International Mother Language Day",
        "2024-02-22": "Be Humble Day",
        "2024-02-23": "International Dog Biscuit Appreciation Day",
        "2024-02-24": "World Bartender Day",
        "2024-02-25": "Quiet Day",
        "2024-02-26": "Tell a Fairy Tale Day",
        "2024-02-27": "International Polar Bear Day",
        "2024-02-28": "National Science Day",
        "2024-02-29": "Leap Day",
        "2024-03-01": "St. David's Day",
        "2024-03-02": "Dr. Seuss Day",
        "2024-03-03": "World Wildlife Day",
        "2024-03-04": "March Forth and Do Something Day",
        "2024-03-05": "Cheese Doodle Day",
        "2024-03-06": "Dentist's Day",
        "2024-03-07": "National Cereal Day",
        "2024-03-08": "International Women's Day",
        "2024-03-09": "Panic Day",
        "2024-03-10": "International Day of Awesomeness",
        "2024-03-11": "Johnny Appleseed Day",
        "2024-03-12": "Plant a Flower Day",
        "2024-03-13": "National Good Samaritan Day",
        "2024-03-14": "Pi Day",
        "2024-03-15": "Ides of March",
        "2024-03-16": "Everything You Do Is Right Day",
        "2024-03-17": "St. Patrick's Day",
        "2024-03-18": "Awkward Moments Day",
        "2024-03-19": "Let's Laugh Day",
        "2024-03-20": "First Day of Spring",
        "2024-03-21": "World Poetry Day",
        "2024-03-22": "World Water Day",
        "2024-03-23": "National Puppy Day",
        "2024-03-24": "World Tuberculosis Day",
        "2024-03-25": "International Waffle Day",
        "2024-03-26": "Make Up Your Own Holiday Day",
        "2024-03-27": "World Theatre Day",
        "2024-03-28": "Respect Your Cat Day",
        "2024-03-29": "National Vietnam War Veterans Day",
        "2024-03-30": "Take a Walk in the Park Day",
        "2024-03-31": "International Transgender Day of Visibility",
        "2024-04-01": "April Fool's Day",
        "2024-04-02": "World Autism Awareness Day",
        "2024-04-03": "Find a Rainbow Day",
        "2024-04-04": "International Carrot Day",
        "2024-04-05": "National Deep Dish Pizza Day",
        "2024-04-06": "Tartan Day",
        "2024-04-07": "World Health Day",
        "2024-04-08": "Draw a Picture of a Bird Day",
        "2024-04-09": "Winston Churchill Day",
        "2024-04-10": "National Siblings Day",
        "2024-04-11": "National Pet Day",
        "2024-04-12": "Grilled Cheese Sandwich Day",
        "2024-04-13": "Scrabble Day",
        "2024-04-14": "International Moment of Laughter Day",
        "2024-04-15": "Tax Day (USA)",
        "2024-04-16": "Save the Elephant Day",
        "2024-04-17": "Haiku Poetry Day",
        "2024-04-18": "International Day for Monuments and Sites",
        "2024-04-19": "National Garlic Day",
        "2024-04-20": "Chinese Language Day",
        "2024-04-21": "Kindergarten Day",
        "2024-04-22": "Earth Day",
        "2024-04-23": "World Book Day",
        "2024-04-24": "Armenian Genocide Remembrance Day",
        "2024-04-25": "World Penguin Day",
        "2024-04-26": "National Pretzel Day",
        "2024-04-27": "Tell a Story Day",
        "2024-04-28": "Superhero Day",
        "2024-04-29": "International Dance Day",
        "2024-04-30": "Honesty Day",
        "2024-05-01": "May Day",
        "2024-05-02": "Brothers and Sisters Day",
        "2024-05-03": "World Press Freedom Day",
        "2024-05-04": "Star Wars Day",
        "2024-05-05": "Cinco de Mayo",
        "2024-05-06": "National Nurses Day",
        "2024-05-07": "National Tourism Day",
        "2024-05-08": "World Red Cross Day",
        "2024-05-09": "Europe Day",
        "2024-05-10": "Clean Up Your Room Day",
        "2024-05-11": "Twilight Zone Day",
        "2024-05-12": "International Nurses Day",
        "2024-05-13": "Leprechaun Day",
        "2024-05-14": "Dance Like a Chicken Day",
        "2024-05-15": "International Day of Families",
        "2024-05-16": "Love a Tree Day",
        "2024-05-17": "World Telecommunication and Information Society Day",
        "2024-05-18": "No Dirty Dishes Day",
        "2024-05-19": "World Plant a Vegetable Garden Day",
        "2024-05-20": "Be a Millionaire Day",
        "2024-05-21": "National Memo Day",
        "2024-05-22": "International Day for Biological Diversity",
        "2024-05-23": "World Turtle Day",
        "2024-05-24": "Scavenger Hunt Day",
        "2024-05-25": "Towel Day",
        "2024-05-26": "Sally Ride Day",
        "2024-05-27": "Memorial Day (USA)",
        "2024-05-28": "National Hamburger Day",
        "2024-05-29": "Learn About Composting Day",
        "2024-05-30": "Water a Flower Day",
        "2024-05-31": "World No Tobacco Day",
        "2024-06-01": "Global Day of Parents",
        "2024-06-02": "National Rocky Road Day",
        "2024-06-03": "World Bicycle Day",
        "2024-06-04": "Hug Your Cat Day",
        "2024-06-05": "World Environment Day",
        "2024-06-06": "D-Day",
        "2024-06-07": "National Chocolate Ice Cream Day",
        "2024-06-08": "World Oceans Day",
        "2024-06-09": "Donald Duck Day",
        "2024-06-10": "Iced Tea Day",
        "2024-06-11": "National Corn on the Cob Day",
        "2024-06-12": "Red Rose Day",
        "2024-06-13": "Sewing Machine Day",
        "2024-06-14": "Flag Day (USA)",
        "2024-06-15": "Nature Photography Day",
        "2024-06-16": "Father's Day",
        "2024-06-17": "World Day to Combat Desertification and Drought",
        "2024-06-18": "International Sushi Day",
        "2024-06-19": "Juneteenth",
        "2024-06-20": "World Refugee Day",
        "2024-06-21": "First Day of Summer / International Yoga Day",
        "2024-06-22": "National Chocolate Eclair Day",
        "2024-06-23": "International Widows' Day",
        "2024-06-24": "Swim a Lap Day",
        "2024-06-25": "Global Beatles Day",
        "2024-06-26": "Forgiveness Day",
        "2024-06-27": "Helen Keller Day",
        "2024-06-28": "Paul Bunyan Day",
        "2024-06-29": "Camera Day",
        "2024-06-30": "Social Media Day",
        "2024-07-01": "Canada Day",
        "2024-07-02": "World UFO Day",
        "2024-07-03": "Disobedience Day",
        "2024-07-04": "Independence Day (USA)",
        "2024-07-05": "Workaholics Day",
        "2024-07-06": "International Kissing Day",
        "2024-07-07": "World Chocolate Day",
        "2024-07-08": "Video Games Day",
        "2024-07-09": "National Sugar Cookie Day",
        "2024-07-10": "Don't Step on a Bee Day",
        "2024-07-11": "World Population Day",
        "2024-07-12": "Simplicity Day",
        "2024-07-13": "Embrace Your Geekness Day",
        "2024-07-14": "Bastille Day",
        "2024-07-15": "Give Something Away Day",
        "2024-07-16": "World Snake Day",
        "2024-07-17": "World Emoji Day",
        "2024-07-18": "Nelson Mandela International Day",
        "2024-07-19": "National Daiquiri Day",
        "2024-07-20": "Moon Day",
        "2024-07-21": "National Junk Food Day",
        "2024-07-22": "Pi Approximation Day",
        "2024-07-23": "Vanilla Ice Cream Day",
        "2024-07-24": "Amelia Earhart Day",
        "2024-07-25": "Thread the Needle Day",
        "2024-07-26": "Aunt and Uncle Day",
        "2024-07-27": "Take Your Houseplants for a Walk Day",
        "2024-07-28": "World Hepatitis Day",
        "2024-07-29": "International Tiger Day",
        "2024-07-30": "International Day of Friendship",
        "2024-07-31": "Harry Potter's Birthday",
        "2024-08-01": "National Girlfriends Day",
        "2024-08-02": "National Ice Cream Sandwich Day",
        "2024-08-03": "Watermelon Day",
        "2024-08-04": "International Beer Day",
        "2024-08-05": "National Underwear Day",
        "2024-08-06": "Hiroshima Day",
        "2024-08-07": "National Lighthouse Day",
        "2024-08-08": "International Cat Day",
        "2024-08-09": "Book Lovers Day",
        "2024-08-10": "Lazy Day",
        "2024-08-11": "Son and Daughter Day",
        "2024-08-12": "International Youth Day",
        "2024-08-13": "Left Handers Day",
        "2024-08-14": "National Creamsicle Day",
        "2024-08-15": "Relaxation Day",
        "2024-08-16": "Tell a Joke Day",
        "2024-08-17": "National Thrift Shop Day",
        "2024-08-18": "Bad Poetry Day",
        "2024-08-19": "World Photography Day",
        "2024-08-20": "National Radio Day",
        "2024-08-21": "Senior Citizens Day",
        "2024-08-22": "Be an Angel Day",
        "2024-08-23": "Ride the Wind Day",
        "2024-08-24": "National Waffle Day",
        "2024-08-25": "Kiss and Make Up Day",
        "2024-08-26": "Women's Equality Day",
        "2024-08-27": "Just Because Day",
        "2024-08-28": "Race Your Mouse Day",
        "2024-08-29": "More Herbs, Less Salt Day",
        "2024-08-30": "National Toasted Marshmallow Day",
        "2024-08-31": "Trail Mix Day",
        "2024-09-01": "World Letter Writing Day",
        "2024-09-02": "Labor Day (USA)",
        "2024-09-03": "Skyscraper Day",
        "2024-09-04": "Wildlife Day",
        "2024-09-05": "International Day of Charity",
        "2024-09-06": "Read a Book Day",
        "2024-09-07": "National Beer Lover's Day",
        "2024-09-08": "International Literacy Day",
        "2024-09-09": "Teddy Bear Day",
        "2024-09-10": "World Suicide Prevention Day",
        "2024-09-11": "Patriot Day",
        "2024-09-12": "National Chocolate Milkshake Day",
        "2024-09-13": "Positive Thinking Day",
        "2024-09-14": "National Cream-Filled Donut Day",
        "2024-09-15": "International Day of Democracy",
        "2024-09-16": "International Day for the Preservation of the Ozone Layer",
        "2024-09-17": "Constitution Day (USA)",
        "2024-09-18": "National Cheeseburger Day",
        "2024-09-19": "Talk Like a Pirate Day",
        "2024-09-20": "National Pepperoni Pizza Day",
        "2024-09-21": "International Day of Peace",
        "2024-09-22": "First Day of Autumn",
        "2024-09-23": "Checkers Day",
        "2024-09-24": "Punctuation Day",
        "2024-09-25": "Comic Book Day",
        "2024-09-26": "European Day of Languages",
        "2024-09-27": "World Tourism Day",
        "2024-09-28": "World Rabies Day",
        "2024-09-29": "World Heart Day",
        "2024-09-30": "International Podcast Day",
        "2024-10-01": "International Coffee Day",
        "2024-10-02": "International Day of Non-Violence",
        "2024-10-03": "World Architecture Day",
        "2024-10-04": "World Animal Day",
        "2024-10-05": "World Teachers' Day",
        "2024-10-06": "National Noodle Day",
        "2024-10-07": "World Smile Day",
        "2024-10-08": "World Octopus Day",
        "2024-10-09": "World Post Day",
        "2024-10-10": "World Mental Health Day",
        "2024-10-11": "International Day of the Girl Child",
        "2024-10-12": "National Savings Day",
        "2024-10-13": "International Skeptics Day",
        "2024-10-14": "Columbus Day (USA)",
        "2024-10-15": "Global Handwashing Day",
        "2024-10-16": "World Food Day",
        "2024-10-17": "International Day for the Eradication of Poverty",
        "2024-10-18": "World Menopause Day",
        "2024-10-19": "Evaluate Your Life Day",
        "2024-10-20": "International Sloth Day",
        "2024-10-21": "Reptile Awareness Day",
        "2024-10-22": "International Stuttering Awareness Day",
        "2024-10-23": "Mole Day",
        "2024-10-24": "United Nations Day",
        "2024-10-25": "World Pasta Day",
        "2024-10-26": "National Pumpkin Day",
        "2024-10-27": "World Day for Audiovisual Heritage",
        "2024-10-28": "National Chocolate Day",
        "2024-10-29": "Internet Day",
        "2024-10-30": "Checklist Day",
        "2024-10-31": "Halloween",
        "2024-11-01": "All Saints' Day",
        "2024-11-02": "All Souls' Day",
        "2024-11-03": "Sandwich Day",
        "2024-11-04": "National Candy Day",
        "2024-11-05": "Guy Fawkes Night",
        "2024-11-06": "International Day for Preventing the Exploitation of the Environment in War and Armed Conflict",
        "2024-11-07": "National Bittersweet Chocolate with Almonds Day",
        "2024-11-08": "World Urbanism Day",
        "2024-11-09": "World Freedom Day",
        "2024-11-10": "Sesame Street Day",
        "2024-11-11": "Remembrance Day",
        "2024-11-12": "World Pneumonia Day",
        "2024-11-13": "World Kindness Day",
        "2024-11-14": "World Diabetes Day",
        "2024-11-15": "America Recycles Day",
        "2024-11-16": "International Day for Tolerance",
        "2024-11-17": "World Prematurity Day",
        "2024-11-18": "World Philosophy Day",
        "2024-11-19": "International Men's Day",
        "2024-11-20": "Universal Children's Day",
        "2024-11-21": "World Television Day",
        "2024-11-22": "Thanksgiving Day (USA)",
        "2024-11-23": "Black Friday",
        "2024-11-24": "Small Business Saturday",
        "2024-11-25": "International Day for the Elimination of Violence against Women",
        "2024-11-26": "Cake Day",
        "2024-11-27": "Advent",
        "2024-11-28": "Cyber Monday",
        "2024-11-29": "Giving Tuesday",
        "2024-11-30": "Computer Security Day",
        "2024-12-01": "World AIDS Day",
        "2024-12-02": "International Day for the Abolition of Slavery",
        "2024-12-03": "International Day of Persons with Disabilities",
        "2024-12-04": "National Cookie Day",
        "2024-12-05": "International Volunteer Day",
        "2024-12-06": "St. Nicholas Day",
        "2024-12-07": "International Civil Aviation Day",
        "2024-12-08": "Pretend to Be a Time Traveler Day",
        "2024-12-09": "International Anti-Corruption Day",
        "2024-12-10": "Human Rights Day",
        "2024-12-11": "International Mountain Day",
        "2024-12-12": "National Gingerbread House Day",
        "2024-12-13": "National Day of the Horse",
        "2024-12-14": "Monkey Day",
        "2024-12-15": "Bill of Rights Day",
        "2024-12-16": "National Chocolate Covered Anything Day",
        "2024-12-17": "Wright Brothers Day",
        "2024-12-18": "International Migrants Day",
        "2024-12-19": "National Hard Candy Day",
        "2024-12-20": "International Human Solidarity Day",
        "2024-12-21": "Winter Solstice",
        "2024-12-22": "National Cookie Exchange Day",
        "2024-12-23": "Festivus",
        "2024-12-24": "Christmas Eve",
        "2024-12-25": "Christmas Day",
        "2024-12-26": "Boxing Day (Canada, UK, Australia)",
        "2024-12-27": "National Fruitcake Day",
        "2024-12-28": "Holy Innocents' Day",
        "2024-12-29": "Tick Tock Day",
        "2024-12-30": "Bacon Day",
        "2024-12-31": "New Year's Eve"
      }
      const todayHoliday = Holidays[today];
      const categoryPrompts = {
        'Famous Quotes': 'Generate a unique famous quote relevant to business or general life. Ensure the quote is distinct from previous responses by categorizing it under themes like "leadership," "innovation," "perseverance," "teamwork," or "success." Only one quote, not unknown THE POPULAR QUOTE. Please don’t add any other text just a quote. The quote should not be of UNknown author, author should be well known.',
        'Educational Content': 'Create a piece of educational content that provides useful and unique information. Ensure the response covers a different topic or aspect, such as "history," "science," "technology," "health," or "personal development."',
        'Seasonal-Holiday Posts': `Today's event is ${todayHoliday}. Whether it's a day of celebration or a moment of reflection, let's acknowledge its significance together. Share your thoughts or memories related to ${todayHoliday}. #${todayHoliday} #Reflection`,
        'Engagement Posts': 'Create a unique question or prompt to engage the audience. Ensure the question is distinct by focusing on various themes like "daily habits," "personal goals," "favorite hobbies," "memorable experiences," or "current events."',
        'Fun Facts': 'Generate an interesting and unique fun fact. Ensure the fact is different by categorizing it under themes like "animals," "history," "space," "human body," or "technology."',
        'Humorous Posts': 'Create a funny and unique meme or joke. Ensure the response is distinct by focusing on different themes such as "workplace humor," "daily life," "technology jokes," "relationship humor," or "puns."',
        'Quote of the Day': 'Generate a unique motivational or thought-provoking quote for the day. Ensure the quote is distinct and categorized under themes like "inspiration," "wisdom," "courage," "creativity," or "resilience."',
        'Interactive Content': 'Create a unique piece of interactive content like a poll or challenge. Ensure the response offers a different activity by focusing on various themes like "preferences," "trivia," "challenges," "surveys," or "quizzes."'
      };

      let prompt = categoryPrompts[this.selectedCategory];

      if (this.additionalInfo) {
        prompt += ` Additional details: ${this.additionalInfo}`;
      }

      return prompt;
    },

    fetchPosts() {

      // console.log(this.generatePrompt())

      this.$store
        .dispatch('posts/getPosts', {
          user_id: this.user.id,
          business_id: this.user.business.id,
          limit: this.loadedPostsCount // Limit the number of posts fetched
        })
        .then((res) => {
          if (res.length < this.loadedPostsCount) {
            // If fetched posts count is less than requested, assume no more posts available
            this.hasMorePosts = false;
          }
          this.posts = res.map(post => {
            if(post.image_url)
              post.imageUrl = post.image_url.replace(/\\\//g, '/');
            return post;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>

.prompts_container{
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
}

.post-generation {
  align-items: flex-start;
  justify-content: space-between;
  display: grid;
  grid-template-columns: 4fr 1fr;
  //flex-direction: row;
  gap: 1rem;
  width: 100%;
  background-color: white;
  padding: 30px;
  border-radius: 20px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.type-span {
  margin: 5px;
  font-weight: bold;
  color: #868686;
  font-size: 15px;
}

.input-group {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
}

.text-group-first {
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.text-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.select {
  width: 200px;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.input-text {
  flex-direction: row;
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  height: 100px;
  border-radius: 20px;
  margin-right: 20px;
}

.generate-btn {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 20px;
}

.generate-btn:disabled {
  background-color: #6c757d;
}

.loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.container {
  display: flex;
}

.content {
  flex: 1;
  padding: 32px;
  @media(max-width: 768px){
    padding: 20px 0;
  }
}

.content-page{
  padding: 0 !important;
}

.post-generation {
  margin-bottom: 32px;
}

.formatteddate {
  margin-top: 5px;
  font-size: 11px;
  font-weight: bold;
  color: red;
}

.buttonspan {
  display: flex;
  flex-direction: column;
}

.input-group {
  display: flex;
  margin-bottom: 16px;
}

.select {
  width: 100% !important;
  margin-right: 20px;
  border: 1px solid #ccc;
  padding: 8px;
  margin-right: 20px;
  padding: 10px;
  border-radius: 10px;
}

.input-text {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px;
  width: 100%;
}

.generate-btn {
  color: white;
  padding: 8px 40px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  margin-top: 30px !important;
}

.recent-posts .section-title {
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 16px;
  margin-left: 20px;
  color: gray;
}

.recent-posts {
  background: white;
  padding: 30px;
  border-radius: 20px;
}

.recentPost {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  border: 1px solid lightgray;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 16px;
  @media(max-width: 992px){
    flex-wrap: wrap;
    padding: 3px;
    .post-image{
      order: 1;
      margin-inline: auto;
      width: 50%;
    }
    .post-header{
      order: 2;
    }
  }
}

.post-header {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 8px;
}

.post-title {
  font-size: 16px;
  margin-left: 20px;
  color: black;
}

.post-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0px;
  margin-left: 30px;
}

.post-btn {
  background-color: #28a745;
  color: white;
  padding: 4px 15px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.spin {
  height: 5rem;
  width: 5rem;
  margin-top: 20%;
  margin-left: 40%;
}

.generate {
  text-align: center;
  color: black;
}

.loading_div {
  height: 300px;
  width: 300px;
}

.post-image {
  margin: 8px 0;
  box-shadow: 3px 3px 10px #d7d7d7;
  width: 30%;
  border-radius: 4px;
}

.post-content {
  color: #6b7280;
  font-size: 14px;
}

.post-date {
  color: #6b7280;
  font-size: 12px;
  margin-top: 4px;
}

.more-link {
  color: #007bff;
  text-decoration: none;
}

.load-more-btn {
  //width: 13%;
}

.load-div {
  justify-content: center;
  display: flex;
  margin-top: 10px;
}


.modal_container {
  min-width: 100vw;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  display: grid;
  justify-content: center;
  align-items: center;
}

.review_post_modal {
  background: #fff;
  height: 100%;
  max-height: 80vh;
  position: relative;
  overflow-y: scroll;
  padding: 1rem;

  .modal_close_icon {
    position: absolute;
    top: .5rem;
    right: .8rem;
    font-weight: 800;
    font-size: 2rem;
    cursor: pointer;
  }
  .modal_content_wrapper{
    padding: 2rem 4rem;
    @media (max-width: 768px){
      padding: 2rem 1rem;
    }
  }
  .modal_content {

    .post_content {
      max-width: 20rem;
      margin-inline: auto;
      margin-bottom: 1rem;
      position: relative;

      @media (max-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: flex-start;
        gap: .5rem;
      }

      .post_text_wrapper {
        background-color: #f2f2f2;
        padding: .5rem .8rem;
        margin-bottom: 5px;

        p {
          margin: 0;
        }
      }

      .post_image_wrapper {
        border: 1px solid gray;

        .post_image {
          width: 100%;
        }
      }

      .edit_post_button {
        border: none;
        background: #284374;
        color: #fff;
        padding: .3rem 1rem;
        border-radius: 4px;
        cursor: pointer;

        @media (min-width: 768px) {
          position: absolute;
          left: 102%;
          top: 0
        }
      }
    }

    .regenrate_buttons_container {
      margin-bottom: .5rem;
      margin-inline: auto;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: .2rem;

      .regenerate_btn {
        margin-inline: .3rem;
        border: none;
        background: #06b050;
        color: #fff;
        padding: .3rem 1.5rem;
        border-radius: 4px;
        font-size: 12px;
        cursor: pointer;
      }
    }

    .social_media_buttons_container {
      margin-inline: auto;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: .2rem;

      .social_post_btn {
        margin-inline: .3rem;
        border: none;
        background: #284374;
        color: #fff;
        padding: .3rem .7rem;
        border-radius: 4px;
        font-size: 12px;
        cursor: pointer;
      }
    }
  }

  .save_btn {
    border: none;
    background: #284374;
    color: #fff;
    padding: .3rem 1rem;
    border-radius: 4px;
    float: inline-end;
    cursor: pointer;
  }
}

.fade {
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  display: none;
}

.fade.show {
  opacity: 1;
}
</style>

<style>
.keymodal .modal-body{
  text-align: center !important;
  font-size: 17px !important;
  color: black !important;
}
.keymodal .modal-footer{
  display: none !important;
}
.keymodal .btn{
  font-weight: bold;
  font-size: 17px;
}

@media (min-width: 768px) {

  #review_post_modal{
    padding-top: 4rem
  }
}


</style>
